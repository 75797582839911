import React, { useEffect, useRef } from "react";

export interface HubSpotFormProps {
  className?: string;
  portalId: string | number;
  region?: "na1" | "eu1";
  formId: string;
  cssClass?: string;
}

export const HubspotForm = ({
  className,
  portalId,
  region,
  formId,
  cssClass,
}: HubSpotFormProps) => {
  const containerId = useRef<string>();

  useEffect(() => {
    // Container ID needs to be generated in useEffect otherise there will be an SSR error and the randomly
    // generated value will be different on the server and client
    containerId.current = `hs-form-${crypto.randomUUID()}`;

    const scriptId = "hs-form-script";

    const script = document.createElement("script");
    script.id = scriptId;
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.src = "//js.hsforms.net/forms/embed/v2.js";

    const createForm = () => {
      //@ts-ignore
      if (!window.hbspt) {
        console.error("HubSpot script is not loaded!");
        return;
      }

      // @ts-ignore
      window.hbspt.forms.create({
        target: `#${containerId.current}`,
        region,
        portalId,
        formId,
        cssClass,
      });
    };

    const existingScript = document.querySelector(`#${scriptId}`);
    if (existingScript) {
      createForm();
      return;
    }

    script.addEventListener("load", createForm);
    document.body.appendChild(script);
  }, []);

  if (!containerId.current) return <></>;

  return <div id={containerId.current} className={className}></div>;
};
